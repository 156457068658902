import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingBar from './page/LoadingBar';
import { Footer } from './component/Footer';
import Header from './component/Header';
import MapForm from './component/MapForm';
import ContactPage from './component/ContactPage';

// Lazy load components
const Commingsoon = lazy(() => import('./component/Commingsoon'));
const About = lazy(() => import('./page/About'));
const PrivacyPolicy = lazy(() => import('./page/PrivacyPolicy'));
const Electrical = lazy(() => import('./page/Electrical'));
const Plumbing = lazy(() => import('./page/Plumbing'));
const Carpentry = lazy(() => import('./page/Carpentry'));
const Welding = lazy(() => import('./page/Welding'));
const InteriorDesign = lazy(() => import('./page/InteriorDesign'));
const RepairAndInstall = lazy(() => import('./page/Repair&Install'));
const Weddingplanner = lazy(() => import('./page/Weddingplanner'));
const Appliances = lazy(() => import('./page/Applicances'));
const ITServices = lazy(() => import('./page/Itservices'));
const Construction = lazy(() => import('./page/Construction'));
const BookNow = lazy(() => import('./component/FormReg'));
const FeedBack = lazy (()=> import('./component/FeedbackForm'));
const TermandCondition = lazy (()=> import('./page/Termandcondition'));

// const ContractDeal = lazy(()=>import('./component/ContractDeal'));
const NotFound = lazy(()=> import('./page/NotFound'));


const App = () => {
  const pathname = useLocation().pathname;
  const [isLoading, setIsLoading] = useState(true);
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    if (pathname === '' && !redirected) {
      const timer = setTimeout(() => {
        setRedirected(true);
      }, 2000); // Duration of 2 seconds

      return () => clearTimeout(timer);
    } else {
      setIsLoading(false);
    }
  }, [pathname, redirected]);

  if (redirected) {
    return <Navigate to="/landingpage" />;
  }

  return (
    <div className="App">
      {isLoading && <LoadingBar />}
      <Header />
      <ContactPage/>
      {/* <Services/> */}
      <Suspense fallback={<LoadingBar />}>
        <Routes>
          <Route path="/" element={<Navigate to="/landingpage" />} />
          <Route path="/landingpage" element={<Commingsoon />} />
          <Route path="/about_us" element={<About />} />
          <Route path="/electricalwork" element={<Electrical />} />
          <Route path="/contractdeal" element={<NotFound />} />
          <Route path="/notfound" element={<NotFound />} />
          <Route path="/plumbingwork" element={<Plumbing />} />
          <Route path="/term_and_condition" element={<TermandCondition />} />
          <Route path="/constructionwork" element={<Construction />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/carpentrywork" element={<Carpentry />} />
          <Route path="/weldingwork" element={<Welding />} />
          <Route path="/interiordesignwork" element={<InteriorDesign />} />
          <Route path="/repairandinstallwork" element={<RepairAndInstall />} />
          <Route path="/weddingplannerwork" element={<Weddingplanner />} />
          <Route path="/applianceswork" element={<Appliances />} />
          <Route path="/itservices" element={<ITServices />} />
          <Route path="/mapform" element={<BookNow />} />
          <Route path="/feedback" element={<FeedBack />} />
          <Route path="*" element={<Navigate to="/landingpage" />} />
          
        </Routes>
      </Suspense>
      <MapForm />
      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        theme="colored"
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default App;
