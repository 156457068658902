import React, { useState, useEffect, useRef } from 'react';
import { Disclosure } from '@headlessui/react';
import mfaApi from '../api/mfaApi';
import { MenuIcon, XIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { Link, useNavigate } from 'react-router-dom';
import orglogo from '../assests/newurban/NUS Logo.png';
import swal from 'sweetalert';

export default function Header() {
  const [resdata, setResdata] = useState([]);
  const navigate = useNavigate();
  const [isServicesMenuOpen, setIsServicesMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleServicesMenu = () => {
    setIsServicesMenuOpen(!isServicesMenuOpen);
  };

  const closeServicesMenu = () => {
    setIsServicesMenuOpen(false);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const bookingForm = () => {
    navigate('/mapform');
    scrollToTop(); // Scroll to top on booking
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeServicesMenu();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const getServicesDetails = async () => {
    try {
      const response = await mfaApi.get(`/urbanadmin/getservicepublic`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setResdata(response.data.data);
      } else {
        swal(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    getServicesDetails();
  }, []);

  const navigation = [
    { name: 'Home', href: '/LandingPage', current: false },
    { name: 'About us', href: '/about_us', current: false },
    {
      name: 'Services',
      href: '#',
      current: false,
      children: resdata.map(service => {
        const serviceHref =
          service.href && service.href !== 'notfound'
            ? `/${service.href}`
            : '/mapform';

        return {
          name: service.servicename,
          href: serviceHref,
          current: false,
        };
      }),
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div className="min-h-full z-10 sticky top-0 shadow-xl">
      <Disclosure as="nav" className="bg-orange-100">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-2 lg:px-2">
              <div className="flex pt-1 h-16 items-center justify-between">
                {/* Logo */}
                <div className="flex items-center">
                  <Link
                    to="/"
                    className="flex-shrink-0 flex items-center space-x-2"
                  >
                    <img
                      className="flex h-10 w-40 rounded-md"
                      src={orglogo}
                      alt="Your Company"
                    />
                  </Link>
                </div>

                {/* Desktop Navigation */}
                <div className="hidden md:flex items-center">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navigation.map(item =>
                      item.children ? (
                        <div key={item.name} className="relative" ref={menuRef}>
                          <button
                            onClick={toggleServicesMenu}
                            className={classNames(
                              'text-gray-900 hover:bg-gray-200 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-normal flex items-center',
                            )}
                          >
                            {item.name}
                            <ChevronDownIcon
                              className="ml-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>
                          {isServicesMenuOpen && (
                            <div className="absolute left-0 mt-9 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 border-gray-600 border-b-4">
                              <div
                                className="py-1"
                                role="menu"
                                aria-orientation="vertical"
                              >
                                {item.children.map(subItem => (
                                  <Link
                                    key={subItem.name}
                                    to={subItem.href}
                                    className="block px-4 py-3 text-sm text-gray-700 hover:bg-green-100 shadow-sm"
                                    role="menuitem"
                                    onClick={() => {
                                      closeServicesMenu();
                                      scrollToTop(); // Scroll to top when a service is selected
                                    }}
                                  >
                                    {subItem.name}
                                  </Link>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-200 text-gray-900'
                              : 'text-gray-900 hover:bg-gray-200 hover:text-gray-900',
                            'px-3 py-2 rounded-md text-sm font-medium',
                          )}
                          aria-current={item.current ? 'page' : undefined}
                          onClick={scrollToTop} // Scroll to top on link click
                        >
                          {item.name}
                        </Link>
                      ),
                    )}
                  </div>
                  {/* Book Now Button */}
                  <div>
                    <button
                      onClick={bookingForm}
                      className="md:ml-14 bg-orange-600 text-white px-3 py-1 rounded-md text-sm font-normal"
                    >
                      Enquiry Free
                    </button>
                  </div>
                </div>

                {/* Mobile Menu Button */}
                <div className="mr-2 flex md:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-200 p-2 text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-200">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pt-2 mt-10 pb-3 sm:px-3">
                {navigation.map(item =>
                  item.children ? (
                    <Disclosure key={item.name} as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="w-full flex justify-between text-gray-900 hover:bg-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">
                            {item.name}
                            <ChevronDownIcon
                              className={`${
                                open ? 'transform rotate-180' : ''
                              } h-5 w-5`}
                              aria-hidden="true"
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="space-y-1">
                            {item.children.map(subItem => (
                              <Link
                                key={subItem.name}
                                to={subItem.href}
                                className="block px-3 py-2 pl-7 text-sm text-gray-700 hover:bg-gray-100"
                                onClick={() => {
                                  scrollToTop(); // Scroll to top when a service is selected
                                }}
                              >
                                {subItem.name}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      to={item.href}
                      className={`${
                        item.current
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-900 hover:bg-gray-700 hover:text-gray-900'
                      } block px-3 py-2 rounded-md text-base font-medium`}
                      onClick={() => {
                        scrollToTop(); // Scroll to top on link click
                      }}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ),
                )}
                {/* Enquiry Free Button */}
                <div>
                  <button
                    onClick={() => {
                      bookingForm();
                    }}
                    className="md:ml-14 bg-orange-600 text-white px-3 py-1 rounded-md text-sm font-normal"
                  >
                    Enquiry Free
                  </button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
