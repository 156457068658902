import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import mfaApi from '../api/mfaApi';
import swal from 'sweetalert';

const MapForm = () => {
  const [resdata, setResdata] = useState([]);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [pincode, setPincode] = useState('');
  const [enquirydate, setEnquirydate] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // State to hold the selected service name
  const [selectedService, setSelectedService] = useState('');
  console.log(selectedService);

  // const handleEnquiryClick = () => {
  //   // Display mobile numbers in a prompt
  //   // const phoneNumbers = "7004339423, 7631428426, 7631428424";
  //   swal(`You can directly call on given Contact Numbers`);
  // };
  const handleEnquiryClick = async () => {
    if (
      name === '' ||
      address === '' ||
      phone === '' ||
      selectedService === ''
    ) {
      swal('Please enter all fields (non-empty values).');
    } else {
      setIsLoading(true);
      try {
        const response = await mfaApi.post(
          `/urbanadmin/enquiry-form-public`,
          {
            fullname: name,
            email: email,
            phone: phone,
            servicetype: selectedService,
            areapincode: pincode,
            servicedate: enquirydate,
            address: address,
            description: description,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        if (response.status === 201) {
          swal(response.data.message);
          // Reset all input fields
          setName('');
          setPhone('');
          setEmail('');
          setPincode('');
          setEnquirydate('');
          setDescription('');
          setAddress('');
          setSelectedService('');
        }
      } catch (error) {
        swal(error.response?.data?.message || 'An error occurred.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  // const handleBookNowClick = () => {
  //   // Display a message about booking hold
  //   swal(
  //     "Currently, this booking service is on hold. You can make a call to the given number for booking."
  //   );
  // };

  const handleRedirect = () => {
    window.open(
      'https://www.google.com/maps/place/New+urban+services/@25.2465716,86.9914986,17z/data=!3m1!4b1!4m6!3m5!1s0x39f0490022e48825:0x76c8bd156ee93e23!8m2!3d25.2465716!4d86.9940735!16s%2Fg%2F11lp0zjy1c?entry=ttu&g_ep=EgoyMDI0MDkxMS4wIKXMDSoASAFQAw%3D%3D',
      '_blank',
    );
  };

  const getServicesDetails = async () => {
    try {
      const response = await mfaApi.get(`/urbanadmin/getservicepublic`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setResdata(response.data.data);
        // setTotal(response.data);
      } else {
        swal(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    // Place your side effect code here
    // This code will run after the component renders
    getServicesDetails();
    // You can perform cleanup by returning a function
    return () => {};
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, []);

  // Handler function to manage the dropdown change
  const handleChange = event => {
    const selectedValue = event.target.value;
    setSelectedService(selectedValue);
  };

  const [error, setError] = useState('');

  const handlePhoneChange = e => {
    const value = e.target.value;

    // Allow only digits and ensure the input does not exceed 10 digits
    if (/^\d*$/.test(value) && value.length <= 10) {
      setPhone(value);

      // Clear the error if input is valid
      if (value.length === 10) {
        setError('');
      } else {
        setError('');
      }
    } else if (value.length > 10) {
      setError('Phone number cannot exceed 10 digits');
    }
  };
  return (
    <>
      <h2 className="text-sm md:text-2xl font-sans text-black text-center bg-gray-100 ">
        Contact For Booking or Enquiry About Services
      </h2>
      <div className="flex justify-center py-2 bg-gray-100">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24" // Updated viewBox to match the path
          fill="none"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-5 h-5" // Updated to use Tailwind's width and height classes
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
          />
        </svg>
        <a
          href="tel:7004339423"
          className="text-xs md:text-md text-red-700 ml-2"
        >
          7004339423
        </a>
        <a
          href="tel:7631428426"
          className="text-xs md:text-md text-red-700 ml-2"
        >
          7631428426
        </a>
        <a
          href="tel:7631428424"
          className="text-xs md:text-md text-red-700 ml-2"
        >
          7631428424
        </a>
      </div>
      <div className="container w-full px-6 py-2 mx-auto bg-gray-100">
        <div className="grid sm:gap-8 sm:grid-cols-2 xl:grid-cols-2 bg-gray-100">
          <div className="relative h-full p-4 text-gray-800 rounded-lg shadow-md">
            {/* Overlay */}
            <div
              className="absolute inset-0 z-10 cursor-pointer"
              onClick={handleRedirect}
            ></div>

            {/* Google Maps Iframe */}
            {/* <iframe
              title="Hatia Chowk Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.7064871588773!2d86.9936716!3d25.2468091!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f049e4a2764e43%3A0x675b25b17684e07c!2sHatia%20Chowk!5e0!3m2!1sen!2sin!4v1724484953267!5m2!1sen!2sin"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              className="h-full w-full"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe> */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.713541732111!2d86.99149857516521!3d25.246571577679195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f0490022e48825%3A0x76c8bd156ee93e23!2sNew%20urban%20services!5e0!3m2!1sen!2sin!4v1726254275620!5m2!1sen!2sin"
              width="600"
              height="450"
              className="h-full w-full"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="p-2 text-gray-800 rounded-lg ">
            <div className="font-[sans-serif] relative">
              <div className="w-full">
                <form className="bg-white max-w-xl w-full mx-auto shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] p-4 rounded-2xl">
                  <div className="lg:grid md:grid-cols-2 grid gap-8 p-1 my-2">
                    <div>
                      <label className="text-gray-800 text-xs block mb-2">
                        Full Name
                      </label>
                      <div className="relative flex items-center">
                        <input
                          name="name"
                          type="text"
                          value={name}
                          onChange={e => setName(e.target.value)}
                          required
                          className="w-full bg-transparent text-sm text-gray-800 border-b border-gray-300 focus:border-blue-500 px-2 py-3 outline-none"
                          placeholder="Enter name"
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#bbb"
                          stroke="#bbb"
                          className="w-[18px] h-[18px] absolute right-2"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            cx="10"
                            cy="7"
                            r="6"
                            data-original="#000000"
                          ></circle>
                          <path
                            d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                            data-original="#000000"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Phone
                      </label>
                      <input
                        type="text" // Use text to control input length and format
                        name="phone"
                        id="phone"
                        value={phone}
                        onChange={handlePhoneChange}
                        className={`w-full bg-transparent text-sm text-gray-800 border-b border-gray-300 focus:border-blue-500 px-2 py-3 outline-none ${
                          error ? 'border-red-500' : ''
                        }`}
                        required
                      />
                      {error && (
                        <p className="text-red-500 text-sm mt-1">{error}</p>
                      )}
                    </div>
                    <div>
                      <label className="text-gray-800 text-xs block mb-2">
                        Email
                      </label>
                      <div className="relative flex items-center">
                        <input
                          name="email"
                          type="email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          className="w-full bg-transparent text-sm text-gray-800 border-b border-gray-300 focus:border-blue-500 px-2 py-3 outline-none"
                          placeholder="Enter Email"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="text-gray-800 text-xs block mb-2">
                        Service Type
                      </label>
                      <div className="relative flex items-center">
                        <select
                          name="serviceType"
                          required
                          className="w-full bg-transparent text-sm text-gray-800 border-b border-gray-300 focus:border-blue-500 px-2 py-3 outline-none"
                          defaultValue=""
                          onChange={handleChange} // Attach handler
                        >
                          <option value="" disabled hidden>
                            Select service type
                          </option>
                          {resdata.map(service => (
                            <option
                              key={service.id}
                              value={service.servicename}
                            >
                              {service.servicename}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div>
                      <label className="text-gray-800 text-xs block mb-2">
                        Area Pin Code
                      </label>
                      <div className="relative flex items-center">
                        <input
                          name="name"
                          type="number"
                          value={pincode}
                          onChange={e => setPincode(e.target.value)}
                          className="w-full bg-transparent text-sm text-gray-800 border-b border-gray-300 focus:border-blue-500 px-2 py-3 outline-none"
                          placeholder="Enter Pin Code"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-gray-800 text-xs block mb-2">
                        Enquiry Date
                      </label>
                      <div className="relative flex items-center">
                        <input
                          name="servicedate"
                          type="date"
                          value={enquirydate}
                          onChange={e => setEnquirydate(e.target.value)}
                          required
                          className="w-full bg-transparent text-sm text-gray-800 border-b border-gray-300 focus:border-blue-500 px-2 py-3 outline-none"
                          placeholder="Enter name"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-gray-800 text-xs block mb-2">
                        Description
                      </label>
                      <div className="relative flex items-center">
                        <textarea
                          name="name"
                          type="text"
                          value={description}
                          onChange={e => setDescription(e.target.value)}
                          required
                          className="w-full bg-transparent text-sm text-gray-800 border-b border-gray-300 focus:border-blue-500 px-2 py-3 outline-none"
                          placeholder="Enter Description"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-gray-800 text-xs block mb-2">
                        Address
                      </label>
                      <div className="relative flex items-center">
                        <textarea
                          name="name"
                          type="text"
                          required
                          value={address}
                          onChange={e => setAddress(e.target.value)}
                          className="w-full bg-transparent text-sm text-gray-800 border-b border-gray-300 focus:border-blue-500 px-2 py-3 outline-none"
                          placeholder="Enter Address"
                        />
                      </div>
                    </div>
                  </div>
                  {isLoading && (
                    <div className="flex items-center justify-center my-2">
                      <ReactLoading
                        type="bubbles"
                        color="#0143E2"
                        height={'8%'}
                        width={'8%'}
                      />
                    </div>
                  )}
                  {/* <div className="grid sm:gap-8 sm:grid-cols-2 xl:grid-cols-2 bg-gray-100"> */}
                  <div className="flex justify-center">
                    <div className="mt-2">
                      <button
                        type="button"
                        onClick={handleEnquiryClick}
                        className="w-full shadow-xl py-2.5 px-5 text-sm font-semibold tracking-wider rounded-md text-white bg-red-800 hover:bg-cyan-600 focus:outline-none transition-all"
                      >
                        Enquiry
                      </button>
                    </div>
                    {/* <div className="mt-2">
                      <button
                        type="button"
                        onClick={handleBookNowClick}
                        className="w-full shadow-xl py-2.5 px-5 text-sm font-semibold tracking-wider rounded-md text-white bg-green-600 hover:bg-blue-700 focus:outline-none transition-all"
                      >
                        Book Now
                      </button>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MapForm;
