import React from 'react';

const LoadingBar = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gradient-to-r from-gray-100 to-blue-100 z-50">
      {/* Centered Content */}
      <div className="flex flex-col items-center">
        {/* Loading Spinner */}
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-cyan-500 border-solid"></div>
        
        {/* Loading Text with Gradient */}
        <p className="mt-4 text-lg font-semibold bg-gradient-to-r from-cyan-500 to-pink-500 text-transparent bg-clip-text text-center">
          Welcome to New Urban Services...
        </p>
      </div>
    </div>
  );
};

export default LoadingBar;
